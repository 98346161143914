@use 'theme';

.paragraph {
  color: var(--color, theme.get('textColor'));
  line-height: 24px;
  min-height: 16px;

  &:not(:first-of-type) {
    margin-top: theme.spacing(2);
  }

  &:empty {
    display: none;
  }
}
